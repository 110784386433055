<div>
    <mat-card class="" *ngIf="isSelfService() && flowSettingsLoaded && tenantFlowSettingsLoaded;">
        <mat-card-header>
            <mat-card-title>Configurations</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="tenantFlowSettingsOptionsForm" (ngSubmit)="saveConfig()">
                <mat-card-content class="standard-gap-vertical">
                    <div class="gridContent">
                        <mat-spinner *ngIf="integration !== null && hideForm()" [diameter]="20"></mat-spinner>
                        <div [hidden]="hideForm()" class="margin-bottom-10">
                            <div *ngFor="let setting of flowSettings">
                                <div
                                    *ngIf="!setting.isReadOnly && tenantFlowSettingsOptionsForm.get(setting.stepName + '-' + setting.settingName)">
                                    <div *ngIf="setting.type === 'string' || setting.type === 'number'">
                                        <mat-form-field>
                                            <mat-label>{{setting.displayName || setting.settingName}}</mat-label>
                                            <input [formControlName]="setting.stepName + '-' + setting.settingName"
                                                [value]="setting.defaultValue" matInput [required]="setting.required"
                                                [type]="setting.type === 'number' ? 'number':'text'">
                                        </mat-form-field>
                                    </div>
                                    <div *ngIf="setting.type === 'bool'">
                                        <div class="form-group">
                                            <mat-checkbox matInput labelPosition="before" class="enable-checkbox"
                                                [formControlName]="setting.stepName + '-' + setting.settingName"
                                                [checked]="setting.defaultValue">{{setting.displayName ||
                                                setting.settingName}}</mat-checkbox>
                                        </div>
                                    </div>
                                    <div *ngIf="setting.type === 'filter-string'">
                                        <div class="flex-start" style="margin-bottom: 0;">
                                            <mat-form-field class="col-6 text-area-medium" style="margin-bottom: 0;">
                                                <mat-label>
                                                    {{setting.displayName || setting.settingName}}
                                                    <span *ngIf="isRequired(setting)" class="required-asterisk">*</span>
                                                </mat-label>
                                                <mat-chip-grid #chipGrid
                                                    [formControlName]="setting.stepName + '-' + setting.settingName"
                                                    class="chip-grid-inline">
                                                    <mat-chip-row *ngFor="let keyword of getFilters(setting.stepName + '-' + setting.settingName); trackBy: trackByKeyword"
                                                        (removed)="removeFilter(keyword, setting.stepName + '-' + setting.settingName)"
                                                        color="primary">
                                                        <button matChipRemove>
                                                            <mat-icon>close</mat-icon>
                                                        </button>
                                                        {{keyword}}
                                                    </mat-chip-row>
                                                </mat-chip-grid>
                                                <input [matChipInputFor]="chipGrid"
                                                    (matChipInputTokenEnd)="addFilter($event, setting.stepName + '-' + setting.settingName)" 
                                                    (input)="onFilterTextBoxInputChange($event, setting.stepName + '-' + setting.settingName)"
                                                    [formControlName]="setting.stepName + '-' + setting.settingName + '-input'"
                                                    [disabled]="isFilterTextBoxDisabled(setting)"/>
                                            </mat-form-field>
                                            <mat-form-field class="col-6 select-box-small" style="margin-left: 5px; margin-bottom: 0;">
                                                <mat-label>Filter Options</mat-label>
                                                <mat-select
                                                    [formControlName]="setting.stepName + '-' + setting.settingName + '-toggle'"
                                                    (selectionChange)="toggleChange($event, setting.stepName + '-' + setting.settingName)"
                                                    placeholder="Select One">
                                                    <mat-option value="">Select One</mat-option>
                                                    <mat-option value="include">Include Listed Values</mat-option>
                                                    <mat-option value="exclude">Exclude Listed Values</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div *ngIf="setting.type === 'filter-string-with-null'">
                                        <div class="flex-start" style="margin-bottom: 0;">
                                            <mat-form-field class="col-6 text-area-medium" style="margin-bottom: 0;">
                                                <mat-label>
                                                    {{setting.displayName || setting.settingName}}
                                                    <span *ngIf="isRequired(setting)" class="required-asterisk">*</span>
                                                </mat-label>
                                                <mat-chip-grid #chipGrid
                                                    [formControlName]="setting.stepName + '-' + setting.settingName"
                                                    class="chip-grid-inline">
                                                    <mat-chip-row *ngFor="let keyword of getFilters(setting.stepName + '-' + setting.settingName); trackBy: trackByKeyword"
                                                        (removed)="removeFilter(keyword, setting.stepName + '-' + setting.settingName)"
                                                        color="primary">
                                                        <button matChipRemove>
                                                            <mat-icon>close</mat-icon>
                                                        </button>
                                                        {{keyword}}
                                                    </mat-chip-row>
                                                </mat-chip-grid>
                                                <input [matChipInputFor]="chipGrid"
                                                    (matChipInputTokenEnd)="addFilter($event, setting.stepName + '-' + setting.settingName)"
                                                    (input)="onFilterTextBoxInputChange($event, setting.stepName + '-' + setting.settingName)"
                                                    [formControlName]="setting.stepName + '-' + setting.settingName + '-input'"
                                                    [disabled]="isFilterTextBoxDisabled(setting)"/>
                                            </mat-form-field>
                                            <mat-form-field class="col-6 select-box-small" style="margin-left: 5px; margin-bottom: 0;">
                                                <mat-label>Filter Options</mat-label>
                                                <mat-select
                                                    [formControlName]="setting.stepName + '-' + setting.settingName + '-toggle'"
                                                    (selectionChange)="toggleChange($event, setting.stepName + '-' + setting.settingName)"
                                                    placeholder="Select One">
                                                    <mat-option value="">Select One</mat-option>
                                                    <mat-option value="include">Include Listed Values</mat-option>
                                                    <mat-option value="include-with-null">Include Listed Values and Blanks</mat-option>
                                                    <mat-option value="exclude-with-null">Exclude Listed Values and Blanks</mat-option>
                                                    <mat-option value="exclude">Exclude Listed Values</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    
                                    <div *ngIf="setting.type === 'filter-date'">
                                        <mat-form-field class="col-6 text-area-medium">
                                            <mat-label>{{setting.displayName || setting.settingName}}</mat-label>
                                            <mat-date-range-input [rangePicker]="picker" (input)="onDatepickerInputChange($event, setting)">
                                                <input matStartDate placeholder="Start date" [formControlName]="setting.stepName + '-' + setting.settingName+ '-start'" ng-model-options="{timezone: utc}">
                                                <input matEndDate placeholder="End date" [formControlName]="setting.stepName + '-' + setting.settingName+ '-end'" ng-model-options="{timezone: utc}">
                                            </mat-date-range-input>
                                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-date-range-picker #picker></mat-date-range-picker>
                                        </mat-form-field>
                                        <mat-form-field class="col-6 select-box-small" style="margin-left: 5px; margin-bottom: 0;">
                                            <mat-label>Filter Options</mat-label>
                                            <mat-select
                                                [formControlName]="setting.stepName + '-' + setting.settingName + '-toggle'"
                                                (selectionChange)="toggleChange($event, setting.stepName + '-' + setting.settingName)"
                                                placeholder="Select One">
                                                <mat-option value="">Select One</mat-option>
                                                <mat-option value="include">Include</mat-option>
                                                <mat-option value="exclude">Exclude</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-actions class="align-right-down standard-gap-vertical">
                    <div class="start-job-card-actions">
                        <div class="start-job-calculate-button">
                            <button mat-flat-button color="primary" id="start-job" type="submit" class="upload-btn"
                                [disabled]="cannotSubmit()">
                                Save
                            </button>
                        </div>
                        <div class="start-job-calculate-spinner-container">
                            <mat-spinner class="start-job-calculate-spinner" *ngIf="" [diameter]="36"></mat-spinner>
                        </div>
                    </div>
                </mat-card-actions>
            </form>
        </mat-card-content>
    </mat-card>
    <mat-card class="info-card configuration-self-service-disabled" *ngIf="!isSelfService() && flowSettingsLoaded && tenantFlowSettingsLoaded">
        <div class="info-content">
            <mat-icon class="info-icon" color="primary">info</mat-icon>
            <p class="info-text">
                For access to this integration or if you have any questions or comments, contact Paychex at
                <a class="link mailto" href="mailto:paychexintegrationsupport@paychex.com" class="link mailto">paychexintegrationsupport&#64;paychex.com</a>.
            </p>
        </div>
    </mat-card>
    <mat-spinner class="loading-spinner" *ngIf="!jobType || !flowSettingsLoaded || !tenantFlowSettingsLoaded" [diameter]="60"></mat-spinner>
</div>