<mat-grid-list cols="3">
    <mat-grid-tile></mat-grid-tile>
    <mat-grid-tile>
        <div class="amplify-block">
            <amplify-authenticator [hideSignUp]="true" >
                <ng-template amplifySlot="sign-in-header">
                    <h3 class="amplify-heading">Sign In</h3>
                </ng-template>
                <div *ngIf="isPending()">
                    <mat-spinner [diameter]="40"></mat-spinner>
                </div>
                <ng-template amplifySlot="setup-totp-header">
                    <div class="totp">
                        <h3 class="amplify-heading">
                            Set Up Your Authenticator App
                        </h3>
                        <br>
                    </div>
                </ng-template>
                <ng-template amplifySlot="setup-totp-footer">
                    <div class="totp">
                        <p>
                            <b>Secure Your Account with Multi-Factor Authentication (MFA)</b>
                        </p>
                        <p>Multi-Factor Authentication (MFA) adds an extra layer of
                            security to your account by requiring a second form of verification in addition to your
                            password. Your account is setup
                            to leverage an Authenticator App for this additional verification that is unique to you.</p>
                        <p><b>How to Set Up Your Authenticator App</b></p>
                        <p>
                        <ol>
                            <li><b> Download an Authenticator App if you do not already have one installed:</b>

                                <ul><li>Go to the App Store (iOS) or Google Play Store (Android).</li>
                                <li>Search for and download an authenticator app such as Google Authenticator, Microsoft
                                Authenticator, or Authy.</li></ul>
                            </li>
                            <li><b>Open the Authenticator App:</b>
                                <ul><li>Once the app is installed, open it.</li></ul> 
                            </li>
                            <li><b>Add a New Account:</b>
                                <ul><li>In the app, tap on the option to add a new account.
                                Select the option to scan a QR code.</li></ul> 
                            </li>
                            <li><b>Scan the QR Code:</b>
                                <ul><li>Use your phone’s camera to scan the QR code displayed on your screen.</li></ul>
                            </li>
                            <li><b>Enter the Temporary One Time Passcode (TOTP):</b>
                                <ul><li>The app will generate a 6-digit TOTP code.
                                        Enter this code in the "6 TOTP Code" field and click "Confirm."
                                </li></ul>
                            </li>
                        </ol>
                        <p>If you need help, please contact our support team at <a class="link mailto"
                                href="mailto: {{this.mfaHelpDisplayURL}}">{{this.mfaHelpDisplayURL}}</a></p>
                    </div>
                </ng-template>
                <ng-template amplifySlot="sign-in-footer">
                    <div style="text-align: center">
                        <button (click)="onForgotPassword()" amplify-button size="small" variation="link"
                            class="amplify-button amplify-button--fullwidth amplify-button--link amplify-button--small"
                            style="font-weight: normal;">
                            Forgot your password?
                        </button>
                    </div>
                    <div style="text-align: center">
                        <a class="amplify-button amplify-button--link amplify-button--small" style="color: #4A4A4A; font-weight: normal;" href="mailto: {{getSupportURL}}">Get Support</a>
                    </div>
                    <div style="text-align: center; display: flex; justify-content: center; margin-top: 20px;">
                        <a href="{{privacyPolicyDocumentURL}}" target="_blank" amplify-button size="small" variation="link"
                            class="amplify-button amplify-button--link amplify-button--small"
                            style="font-weight: normal;">
                            Privacy Policy
                        </a>
                        <p style="margin: 0; padding-top: 2px;">|</p>
                        <a href="{{termsAndConditionsDocumentURL}}" target="_blank" amplify-button size="small" variation="link"
                            class="amplify-button amplify-button--link amplify-button--small"
                            style="font-weight: normal;">
                            Terms & Conditions
                        </a>
                    </div>
                </ng-template>
            </amplify-authenticator>
        </div>
    </mat-grid-tile>
    <mat-grid-tile></mat-grid-tile>
</mat-grid-list>