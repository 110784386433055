import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule, formatDate } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { job } from '../../jobs/models/job.model';
import { TenantAPIService } from '../../../Services/api.tenantApi.service';
import { ExportableFilesComponent } from '../../../Shared/exportable-files/exportable-files.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { Subject, Subscription, interval, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'job-details-dialog',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatDialogModule, MatDialogContent, MatDialogActions, MatExpansionModule, ExportableFilesComponent, MatGridListModule, MatProgressSpinnerModule],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    }
 ],
  templateUrl: './job-details-dialog.component.html',
  styleUrl: './job-details-dialog.component.scss'
})

export class JobDetailsDialogComponent {

  job: job;
  activeJobEvent: Subject<string> = new Subject<string>();
  timer: Subscription = new Subscription();
  loaded: boolean = false;
  isJobCompleted: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<JobDetailsDialogComponent>,
    private jobsAPI: TenantAPIService,
    @Inject(MAT_DIALOG_DATA) public data: job
  ) {
    this.job = data;
  }

  ngOnInit() {
    if (this.isJobActive(this.job)) {
      this.activeJobEvent.subscribe((id) => id && this.beginGetJobId());
    }
    else {
      this.isJobCompleted = true;
    }
  }

  ngAfterViewInit() {
    this.activeJobEvent.next(this.job.jobId ?? '');
  }

  ngOnDestroy() {
    this.timer.unsubscribe();
  }

  pollForJobStatus() {

    if (!this.job || (this.job.jobStatus != "New" && this.job.jobStatus != "Active")) {
      this.timer.unsubscribe();
      this.isJobCompleted = true;
    }
    else {
        this.getJobById(this.job.jobId ?? "-1");
    }
  }

  beginGetJobId() {
    this.getJobById(this.job.jobId ?? "-1");
    this.timer = interval(15000).pipe(takeUntil(timer(300000))).subscribe((x => {
      this.pollForJobStatus();
    }));
  }

  getJobById = (id: string): void => {
    this.loaded = false;
    this.jobsAPI.getJobById(id).subscribe({
      next: (data: job) => {
        this.job.jobStatus = data.jobStatus; 
      },
      error: (result: any) => { 
        this.timer.unsubscribe();
        console.log(result); 
      },
      complete: () => this.loaded = true
    });
  }

  formattedDate()
  {
    return formatDate(this.job.createdDate ?? new Date(), 'MMM dd yyyy h:mm a', 'en-US');
  }

  isJobActive(job?:job):boolean{
    return job != null && (job.jobStatus === "New" || job.jobStatus === "Active" )
  }
}