import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TenantAPIService } from './api.tenantApi.service';
import { tenantFlowActionResult, toggleStateResult } from "../Models/Class-Interfaces/flow.model";

export interface PollingRequestParams {
  flowType: string;
  flowCode: string;
  stateActionCode: string;
  stateActionId: string;
}

export interface PollingBehavior {
  timeoutMs: number;
  intervalMs: number;
}

// Default polling behavior values
export const DEFAULT_POLLING_BEHAVIOR: Required<PollingBehavior> = {
  timeoutMs: 2 * 60 * 1000,  // 2 minutes timeout
  intervalMs: 1000           // 1 second interval
};

// Transient service used for polling. Destroy when parent page is changing 
// to stop polling on page change.
@Injectable()
export class ActionResultPollingServiceService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(private tenantAPIService: TenantAPIService) {}

  checkFlowActionStatus(
    params: PollingRequestParams,
    behavior: PollingBehavior = DEFAULT_POLLING_BEHAVIOR
  ): Observable<tenantFlowActionResult> {
    return new Observable(observer => {
      const startTime = Date.now();
      let timeoutId: any;

      const checkStatus = () => {
        if (Date.now() - startTime >= behavior.timeoutMs) {
          observer.error(new Error(`Operation timed out after ${behavior.timeoutMs}ms`));
          return;
        }
        console.log("Polling for status...");
        this.tenantAPIService.getFlowActionStatus(params.flowType, params.flowCode, params.stateActionCode, params.stateActionId)
          .pipe(
            takeUntil(this.destroy$) // Stop when destroy$ emits
          )
          .subscribe({
            next: (result: tenantFlowActionResult) => {
              if (result.status === 'complete' || result.status === 'none') {
                observer.next(result);
                observer.complete();
              } else if (result.status === 'error') {
                observer.error(new Error('Polling encountered an error.'));
              } else {
                timeoutId = setTimeout(checkStatus, behavior.intervalMs);
              }
            },
            error: (error: any) => {
              observer.error(error);
            }
          });
      };

      checkStatus();

      // Cleanup function to clear timeout on unsubscription
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    });
  }

  destroy() : void {
    this.destroy$.next();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
