import { Component } from '@angular/core';
import { ActivatedRoute, NavigationStart, Params, Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../../Shared/confirmation-dialog/confirmation-dialog.component';
import { TenantAPIService } from '../../Services/api.tenantApi.service';
import { flow, tenantFlowActionResult, tenantFlowActionResultStatus, toggleStateResult } from '../../Models/Class-Interfaces/flow.model';
import { MatDialog } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CommonModule } from '@angular/common';
import { TransferComponent } from '../transfer/transfer.component';
import { EmailNotificationSettingsComponent } from '../email-notification-settings/email-notification-settings.component';
import { tenantFlowResult } from './models/integration-models';
import { JobsComponent } from '../jobs/jobs.component';
import { TenantConfigurationComponent } from '../tenant-configuration/tenant-configuration.component';
import { TableSettingsComponent } from '../table-settings/table-settings.component';
import { PayrollScheduleComponent } from '../payroll-schedule/payroll-schedule.component';
import { tenantFlowSetting } from '../../Models/Class-Interfaces/tenantFlowSetting.model';
import { jobType } from '../jobs/models/jobtypes.model';
import { Subject, Observable, catchError, throwError, takeUntil, filter } from 'rxjs';
import { AcknowledgementDialogComponent, openAcknowledgementDialog } from '../../Shared/acknowledgement-dialog/acknowledgement-dialog.component';
import { CognitoService } from '../../Services/cognito.service';

@Component({
  selector: 'app-integration',
  standalone: true,
  imports: [EmailNotificationSettingsComponent, CommonModule, JobsComponent, MatProgressSpinnerModule, MatTabsModule, MatSlideToggleModule, TransferComponent, TenantConfigurationComponent,
    TableSettingsComponent, PayrollScheduleComponent
  ],
  templateUrl: './integration.component.html',
  styleUrl: './integration.component.scss'
})
export class IntegrationComponent {
  flowCode: string | null = null;
  flowType: string = "Job";
  integration: tenantFlowResult | null = null;
  loading: boolean = false;
  flowNotFound: boolean = false;
  tenantFlowSettings: tenantFlowSetting[] = [];
  tenantFlowSettingsLoading: boolean = false;
  shouldShowScheduleTab: boolean = false;
  jobType: jobType | null = null;
  isEnabled: boolean = false;
  lastRunDate: Date | null = null;
  isEnabledChanging: boolean = false;
  tenantJobTypeLoading: boolean = false;
  isAdmin: boolean = false;

  // Subject to signal component destruction or page change.
  // Any long running operations or polling should stop when
  // this is emitted.
  private destroy$ = new Subject<void>();

  constructor(private route: ActivatedRoute, private tenantAPIService: TenantAPIService, private dialog: MatDialog, private router: Router, private cognitoService: CognitoService) {
  }

  ngOnInit() {
    this.route.params.subscribe((param: Params) => {
      this.flowCode = param['flowCode'];
      this.isEnabledChanging = false;
      this.lastRunDate = null;
      this.integration = null;
      this.tenantFlowSettings = [];
      this.isAdmin = false;
      this.loading = true;
      this.tenantAPIService.getFlowInstance(this.flowType, this.flowCode ?? "").subscribe({
        next: (result: tenantFlowResult) => {
          let flow: flow = { name: result.name, code: result.flowCode, version: result.version };
          this.integration = result ?? null;
          return flow;
        },
        error: (result: any) => { console.log(result) },
        complete: () => { this.loading = false; }
      });

      this.tenantFlowSettingsLoading = true;
      this.tenantAPIService.getTenantFlowSettings(this.flowCode ?? "").subscribe({
        next: (data: tenantFlowSetting[]) => {
          this.tenantFlowSettings = data;
          this.shouldShowScheduleTab = data.some(d => d.subType === "user-configurable-schedule");
        },
        error: (result: any) => { console.log(result) },
        complete: () => { this.tenantFlowSettingsLoading = false; }
      });

      this.refreshTenantJobType();

    });


    this.router.events.pipe(filter(event => event instanceof NavigationStart))
      .subscribe((e) => {
        // Stop current subscriptions.
        this.destroy$.next();
      });

  }

  refreshTenantJobType() {
    this.tenantJobTypeLoading = true;
    this.isEnabledChanging = true;
      this.tenantAPIService.getJobType(this.flowCode ?? "").subscribe({
        next: (result: jobType) => {
          this.jobType = result;
          this.lastRunDate = result.lastRunDate;
          this.isEnabled = result.isEnabled;
        },
        error: (result: any) => { console.log(result) },
        complete: () => {
          Promise.resolve(this.cognitoService.isInAdminGroup()).then((isAdmin: boolean) => {
            this.isAdmin = isAdmin;
            this.tenantJobTypeLoading = false;
            this.isEnabledChanging = false;
          });
        }
      });
  }

  onToggleChange(event: any) {
    // Prevent the immediate toggle
    event.source.checked = this.isEnabled;

    if (this.loading || this.flowNotFound) {
      return;
    }

    if (this.isEnabled) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Pause Configuration',
          subtitle: 'Are you sure you want to pause this configuration?',
          text: `Pausing will disable any configured schedules, prevent any new integration runs, and halt any in-progress runs.\n\nThis can be undone by re-connecting.`,
          width: '50%',
          height: 'auto',
          okText: 'Pause'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.disableIntegration();
        }
      });
    }
    else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Connect Configuration',
          subtitle: 'Are you sure you want to connect this configuration?',
          text: `Connecting will allow the integration to run which will start any configured schedules and process any required updates to your system.`,
          width: '50%',
          height: 'auto',
          okText: 'Connect'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.enableIntegration();
        }
      });
    }
  }

  disableIntegration() {
    this.isEnabledChanging = true;
    const headers = { 'X-Suppress-Error-Dialog': 'true' };
    this.tenantAPIService.disable(this.flowType, this.flowCode ?? "", headers).pipe(
      catchError(error => {
        console.error(`Error occurred during disableIntegration: ${error}`);
        this.isEnabledChanging = false;
        const dialogRef = this.dialog.open(AcknowledgementDialogComponent, {
          data: { title: 'Unable to Update Status', message: "Something went wrong and we weren't able to update the status of your integration. <br><br>Please try again. If this error continues, please contact your support rep." },
        });
        return throwError(() => new Error(error));
      })
    )
      .pipe(
        takeUntil(this.destroy$) // Stop when destroy$ emits
      )
      .subscribe({
        next: (result: toggleStateResult) => {
          this.checkFlowActionStatus(this.flowType, this.flowCode ?? "", result.stateActionCode, result.stateActionId).pipe(
            catchError(error => {
              this.isEnabledChanging = false;
              const dialogRef = this.dialog.open(AcknowledgementDialogComponent, {
                data: { title: 'Unable to Update Status', message: "Something went wrong and we weren't able to update the status of your integration. <br><br>Please try again. If this error continues, please contact your support rep." },
              });
              dialogRef.afterClosed().subscribe({
                next: (confirmed: boolean) => {
                  this.isEnabledChanging = false;
                }
              });
              this.isEnabled = true;
              return throwError(() => new Error(error));
            })
          ).subscribe({
            next: (statusResult: tenantFlowActionResult) => {
              if(statusResult.status === 'error')
              {
                this.isEnabled = true;
              }
              else
              {
                this.isEnabled = false;
              }
            },
            error: () => {
              this.refreshTenantJobType();
            },
            complete: () => {
              this.isEnabledChanging = false;
            }
          });
        }
      });
  }

  enableIntegration() {
    this.isEnabledChanging = true;
    const headers = { 'X-Suppress-Error-Dialog': 'true' };
    this.tenantAPIService.enable(this.flowType, this.flowCode ?? "", headers).pipe(
      catchError(error => {
        console.error(`Error occurred during enableIntegration: ${error}`);
        this.isEnabledChanging = false;
        const dialogRef = this.dialog.open(AcknowledgementDialogComponent, {
          data: { title: 'Unable to Update Status', message: "Something went wrong and we weren't able to update the status of your integration. <br><br>Please try again. If this error continues, please contact your support rep." },
        });
        return throwError(() => new Error(error));
      })
    )
      .pipe(
        takeUntil(this.destroy$) // Stop when destroy$ emits
      )
      .subscribe({
        next: (result: toggleStateResult) => {
          this.checkFlowActionStatus(this.flowType, this.flowCode ?? "", result.stateActionCode, result.stateActionId).pipe(
            catchError(error => {
              this.isEnabledChanging = false;
              const dialogRef = this.dialog.open(AcknowledgementDialogComponent, {
                data: { title: 'Unable to Update Status', message: "Something went wrong and we weren't able to update the status of your integration. <br><br>Please try again. If this error continues, please contact your support rep." },
              });
              dialogRef.afterClosed().subscribe({
                next: (confirmed: boolean) => {
                  this.isEnabledChanging = false;
                }
              });
              this.isEnabled = false;
              return throwError(() => new Error(error));
            })
          ).subscribe({
            next: (statusResult: tenantFlowActionResult) => {
              if(statusResult.status === 'error')
                {
                  this.isEnabled = false;
                }
                else
                {
                  this.isEnabled = true;
                }
            },
            error: () => {
              this.refreshTenantJobType();
            },
            complete: () => {
              this.isEnabledChanging = false;
            }
          });
        }
      });
  }

  checkFlowActionStatus(flowType: string, flowCode: string, stateActionCode: string, stateActionId: string): Observable<tenantFlowActionResult> {
    return new Observable(observer => {
      const startTime = Date.now();
      const timeoutDuration_ms = 2 * 60 * 1000;
      let timeoutId: any;

      const checkStatus = () => {
        if (Date.now() - startTime >= timeoutDuration_ms) {
          observer.error(new Error('Operation timed out'));
          return;
        }
        console.log("poll");
        this.tenantAPIService.getFlowActionStatus(flowType, flowCode, stateActionCode, stateActionId)
          .pipe(
            takeUntil(this.destroy$) // Stop when destroy$ emits
          )
          .subscribe({
            next: (result: tenantFlowActionResult) => {
              if (result.status === 'complete' || result.status === 'none') {
                observer.next(result);
                observer.complete();
              }
              else if (result.status === 'error') {
                observer.error(new Error());
              }
              else {
                timeoutId = setTimeout(checkStatus, 1000);
              }
            },
            error: (error: any) => {
              observer.error(error);
            }
          });
      };
      checkStatus();

      // Cleanup function
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    });
  }
}