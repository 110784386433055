import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogConfig, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';

@Component({
    selector: 'app-acknowledgement-dialog',
    standalone: true,
    imports: [MatDialogActions, MatDialogContent, MatDialogModule, MatButtonModule, MatDialogTitle],
    templateUrl: './acknowledgement-dialog.component.html',
    styleUrls: ['./acknowledgement-dialog.component.scss']
})
export class AcknowledgementDialogComponent {
    title: string = "Error";
    message: string = "An error occurred.";
    closeText: string = "Close";

    constructor(
        public dialogRef: MatDialogRef<AcknowledgementDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AcknowledgementDialogData
    ) {
        console.log('AcknowledgementDialogComponent data:', data);
        if (data) {
            this.title = data.title ?? this.title;
            this.message = data.message ?? this.message;
            this.closeText = data.closeText ?? this.closeText;
        }
        console.log('AcknowledgementDialogComponent title:', this.title);
        console.log('AcknowledgementDialogComponent message:', this.message);
        console.log('AcknowledgementDialogComponent closeText:', this.closeText);
    }

    close(): void {
        this.dialogRef.close();
    }
}

export function openAcknowledgementDialog(dialog: MatDialog, data?: AcknowledgementDialogData): MatDialogRef<AcknowledgementDialogComponent> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '300px';
    dialogConfig.height = '200px';

    console.log(`openAcknowledgementDialog: ${JSON.stringify(data)}`);

    return dialog.open(AcknowledgementDialogComponent, {
        data: data,
        ...dialogConfig
    });
}

interface AcknowledgementDialogData {
    title?: string;
    message?: string;
    closeText?: string;
}