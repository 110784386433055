import { Component, Input } from '@angular/core';
import { CommonModule, formatDate } from '@angular/common';
import { TenantAPIService } from '../../Services/api.tenantApi.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { job, startJobRequest } from './models/job.model';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { JobInfoCardComponent } from './job-info-card/job-info-card.component';
import { formatDateToMMDDYYYY } from '../../Shared/utility-functions';
import { jobOptions } from './models/jobOptions.model';
import { Router } from '@angular/router';
import { jobType, jobTypeConfig } from './models/jobtypes.model';
import { JobDetailsDialogComponent } from '../job-search/job-details-dialog/job-details-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PaginatedResponse } from '../../Shared/models/paginatedResponse.model';
import { FlowSettingsService } from '../../Services/api.flowSettings.service';
import { flowDefinitionSetting } from './models/flowDefinitionSetting';
import { tenantFlowResult } from '../integration/models/integration-models';
@Component({
  selector: 'app-jobs',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MatInputModule, MatSelectModule, MatFormFieldModule, MatProgressSpinnerModule, MatGridListModule, 
    MatButtonModule, MatIconModule, MatDatepickerModule, MatNativeDateModule, MatDividerModule, MatCardModule, JobInfoCardComponent, MatDividerModule],
    templateUrl: './jobs.component.html',
  styleUrl: './jobs.component.scss'
})
export class JobsComponent {
  constructor(private jobsAPI: TenantAPIService, private flowSettingsAPI: FlowSettingsService, private dialog: MatDialog, private router: Router, private fb: FormBuilder) 
  {
    this.jobStartOptionsForm = this.fb.group({
      name: [''],
      description: ['']
    });
  }

  @Input() integration: tenantFlowResult | null = null

  jobTypes: jobType[] = [];
  jobStarted: boolean = false;
  jobId: string | null = null;
  jobFlowSettings: flowDefinitionSetting[] = [];
  jobFlowSettingsLoaded: boolean = false;

  jobStartOptionsForm: FormGroup;

  ngOnInit() {
    if(this.integration !== null){
      this.flowSettingsAPI.getRuntimeFlowDefinitionSettings(this.integration.flowCode, this.integration.version).subscribe({
        next: (data: flowDefinitionSetting[]) => {
          this.jobFlowSettings = data;
        },
        error: (result: any) => { console.log(result) },
        complete: () => { 
          this.jobFlowSettingsLoaded = true;
          this.updateForm(this.jobFlowSettings);
        }
      });
    }
  }
  

  onJobTypeSelectionChange(selectedType: jobType){
    const selectedJobType = this.jobTypes.find(type => type.jobTypeId === selectedType.jobTypeId);
    
    if(selectedJobType !== undefined){
      this.flowSettingsAPI.getRuntimeFlowDefinitionSettings(selectedJobType.jobTypeId, selectedJobType.version).subscribe({
        next: (data: flowDefinitionSetting[]) => {
          this.jobFlowSettings = data;
        },
        error: (result: any) => { console.log(result) },
        complete: () => { 
          this.jobFlowSettingsLoaded = true;
          this.updateForm(this.jobFlowSettings);
        }
      });
    }
  }

  updateForm(jobFlowSettings: flowDefinitionSetting[]) {
    if(this.jobFlowSettingsLoaded == false)
    {
      return;
    }

    jobFlowSettings = jobFlowSettings.sort((a, b) => {
      let orderA = a.sortPriority !== null ? a.sortPriority : Number.MAX_SAFE_INTEGER;
      let orderB = b.sortPriority !== null ? b.sortPriority : Number.MAX_SAFE_INTEGER;

      return orderA - orderB;
    });

    Object.keys(this.jobStartOptionsForm.controls).forEach(key => {

      // Avoid nuking our static form options tied to the job and not jobTypeOptions
      if(!['name','description'].includes(key)){
        this.jobStartOptionsForm.removeControl(key);
      }
    });

    // Add new form controls on switch type
    jobFlowSettings.forEach(setting => {
      let formControlDefault: any | null = null;

      if(!setting.isReadOnly) {
        // Update default value based on type
        if(this.isDateTimeType(setting.type) && setting.defaultValue != null){
          const parsedDate = new Date(setting.defaultValue);
          formControlDefault = !isNaN(parsedDate.valueOf()) ? parsedDate : null; // Make sure valid date string.
        }
        else{
          formControlDefault = setting.defaultValue;
        }

        const control = this.fb.control(formControlDefault,
          setting.required ? Validators.required : null)
        this.jobStartOptionsForm.addControl(setting.settingName, control);
      }
    });
  }

  // Triggered when a datepicker changes the date, either by a complete valid date input or mat-datepicker calendar selection.
  onDateChange(event: MatDatepickerInputEvent<Date>, controlName: string, dependentControlName?: string | null){
    const date: Date | null = event.value; // endDate in this context if dependentControlName is valid

    if(dependentControlName){
      const dependentDate = this.jobStartOptionsForm.get(dependentControlName)?.value; // startDate if valid
      if(date && dependentDate && date < dependentDate) {
        this.jobStartOptionsForm.controls[controlName].setErrors({endDateBeforeStartDate: true});
      }
      else{
        this.jobStartOptionsForm.controls[controlName].setErrors(null);
      }
    }
  }

  getFormDataAsDictionaryOptions() {
    const formValues = {...this.jobStartOptionsForm.value};
    const optionsDictionary: jobOptions = { }; // keep '.options' empty for now
    
    delete formValues.name; // Handled separately from options
    delete formValues.description // Handled separately from options

    Object.keys(formValues).forEach(key => {
      let controlValue = formValues[key];

      if(controlValue instanceof Date){
        optionsDictionary[key] = formatDateToMMDDYYYY(controlValue);
      }
      else if(controlValue !== undefined && controlValue !== null) {
        optionsDictionary[key] = controlValue.toString().trim()
      }
    });

    return optionsDictionary;
  }

  startJob = (): void => {
    if (this?.integration?.name) {
      this.jobStarted = true;
      
      let newJob: startJobRequest = {
        name: this.jobStartOptionsForm.get("name")?.value.trim() || undefined,
        jobCode: this.integration?.flowCode,
        description: this.jobStartOptionsForm.get("description")?.value.trim() || undefined,
        options: this.getFormDataAsDictionaryOptions()
      };

      //TODO add configuration
      this.jobsAPI.startJob(newJob).subscribe({
        next: (data: job) => {
          this.jobId = data?.jobId ?? null;
        },
        error: (result: any) => { console.log(result) },
        complete: () => {
          this.jobStarted = false
          if(this.jobId !== null){
            this.getJobAndShowDialog(this.jobId);
            this.router.navigate(['/jobs/search'], {queryParams: {jobID: this.jobId}});
          }
        }
      });
    }
    else {
      console.log("job type must be selected before starting a job");
    }
  }

  getJobAndShowDialog(id: string){
    this.jobsAPI.getJobs(id).subscribe({
      next: (data: PaginatedResponse<job>) => {
        data.data.forEach(obj => {
          obj.createdDate = formatDate(obj.createdDate ?? new Date(), 'MMM dd yyyy h:mm a', 'en-US');
        })
        this.showJobDetails(data.data[0]);
      },
      error: (result: any) => { console.log(result) },
      complete: () => {
      }
    });
  }

  showJobDetails(element?: job) {
    const dialogRef = this.dialog.open(JobDetailsDialogComponent, {
      data: element,
    });
  }

  hideForm = (): boolean => {
    return this.integration?.flowCode == null || !this.jobFlowSettingsLoaded; // Proxy for unloaded job types
  }

  getJobId = (): string => {
    return this.jobId != null ? this.jobId : ""; 
  }

  isDateTimeType(typeValue: string) : boolean {
    return typeValue === 'date' || typeValue === 'dateTime' || typeValue === 'datetime';
  }
}
