<form class="app-searchable-dropdown">
    <mat-form-field class="searchable-dropdown-field">
        <mat-icon matSuffix>expand_more</mat-icon>
        <input #searchInput class="searchable-input-field" type="text" placeholder="Search" [formControl]="searchableDropdownControl" matInput [matAutocomplete]="auto" (input)="filter()" (focus)="filter()">
        <mat-autocomplete class="autocomplete-field" #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectionChangeFunction($event)" [requireSelection]="setAsRequired" 
        (opened)="dropdownOpened()" (closed)="dropdownClosed()">
            @for (option of filteredOptions; track option) {
                <mat-option [value]="option">{{option.name}}</mat-option>
            }
        </mat-autocomplete>
    </mat-form-field>
</form>
