import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { MIPNotification, NotificationsService } from '../Shared/Notifications/notifications.service';

import { EMPTY, Observable, catchError, tap, throwError } from 'rxjs';
import { MIPNotificationType } from '../Shared/Notifications/mip-notification-type.enum';
import { inject } from '@angular/core';
import { CognitoService } from '../Services/cognito.service';

interface ProblemDetails {
  title: string;
  detail: string;
  type: string;
  status: number;
  extendedInfo: { [key: string]: any };
}

export const httpResponseNotificationInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  const notificationsService = inject(NotificationsService);
  const cognitoService = inject(CognitoService);

  return next(req).pipe(
      tap(event => {
          if (event instanceof HttpResponse) {
              if (event.status === 200 || event.status === 201) {
                  // Handle success if needed
              }
          }
      }),
      catchError((error: HttpErrorResponse) => {
          const suppressErrorDialog = req.headers.get('X-Suppress-Error-Dialog');
          if (suppressErrorDialog) {
              return throwError(() => new Error(error.message));
          }

          let problemDetails: ProblemDetails | null = null;
          if (error.status === 401 || error.status === 403) {
              cognitoService.handleSignOut();
              return EMPTY;
          }
          try {
              problemDetails = error.error as ProblemDetails;
          } catch (e) {
              console.error("Cannot parse response as ProblemDetails.");
          }
          const title = problemDetails?.title ?? "System Error";
          const errorMessage = problemDetails?.detail || error?.error?.message || "An unknown error has occurred";

          notificationsService.queueNotification({
              type: MIPNotificationType.Error,
              title: title,
              message: errorMessage,
              jsonDetails: problemDetails?.extendedInfo
          } as MIPNotification);

          return throwError(() => new Error(errorMessage));
      })
  );
};
