import { HttpClient, HttpContextToken, HttpHeaders, HttpParams } from '@angular/common/http';
import { CognitoService } from './cognito.service';
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs';
export const SET_AWS_TOKEN = new HttpContextToken(() => true);
export abstract class MipHttpService {
  private baseURI: string;
  constructor(private cognitoService: CognitoService, private httpClient: HttpClient) {
    console.log(environment);
    let e = environment;
    this.baseURI = environment.BaseAPIUrl;
  }

  // Handles api calls that return a string instead of a json object  
  public getResultAsString(path: string, parameters?: { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; }) {
    let uri: string = this.buildURI(path);
    let header: HttpHeaders = this.buildHeaders();
    var params = new HttpParams()
    return this.httpClient.get(uri, { headers: header, params: params, reportProgress: false, responseType: 'text' });
  }

  public get<type>(path: string, parameters?: { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; }) {
    let uri: string = this.buildURI(path);
    let header: HttpHeaders = this.buildHeaders();
    let params = new HttpParams();

    if (parameters) {
      Object.keys(parameters).forEach(key => {
        if (parameters[key] !== null && parameters[key] !== undefined) {
          params = params.append(key, parameters[key].toString())
        }
      })
    }

    return this.httpClient.get<type>(uri, { headers: header, params: params });
  }
  
  public post<type>(path: string, body: any, customHeaders?: { [key: string]: string }): Observable<type> {
    const uri: string = this.buildURI(path);
    const headers: HttpHeaders = this.buildHeaders(customHeaders);
    const params = new HttpParams();
    return this.httpClient.post<type>(uri, body, { headers: headers, params: params });
  }

  public put<type>(path: string, body: string) {
    let uri: string = this.buildURI(path);
    let header: HttpHeaders = this.buildHeaders();
    var params = new HttpParams()
    return this.httpClient.put<type>(uri, body, { headers: header });
  }

  public options(path: string) {
    let uri: string = this.buildURI(path);
    let header: HttpHeaders = this.buildHeaders();
    var params = new HttpParams()
    return this.httpClient.options(uri, { headers: header, params: params, observe: 'response' });
  }

  private buildHeaders(customHeaders?: { [key: string]: string }): HttpHeaders {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    if (customHeaders) {
      for (const key in customHeaders) {
        if (customHeaders.hasOwnProperty(key)) {
          headers = headers.set(key, customHeaders[key]);
        }
      }
    }

    return headers;
  }

  private buildURI(path: string): string {
    return this.baseURI + path;
  }
}