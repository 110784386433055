import { Injectable } from "@angular/core";
import { MipHttpService } from "./api.mipHttpService";
import { CognitoService } from "./cognito.service";
import { HttpClient } from "@angular/common/http";
import { catchError, map, Observable, of, throwError } from "rxjs";
import { JobStatus, exportResult, job, retryJobRequest, retryJobResponse, startJobRequest } from "../Pages/jobs/models/job.model";
import { tenant } from "../Models/tenant.model";
import { transferType } from "../Pages/transfer/models/transferType.model";
import { PaginatedResponse } from "../Shared/models/paginatedResponse.model";
import { initiateTransferResponse } from "../Pages/transfer/models/initiateTransferResponse.model";
import { processTransferResult } from "../Pages/transfer/models/processTransferResult.model";
import { jobType } from "../Pages/jobs/models/jobtypes.model";
import { jobItem } from "../Pages/jobs/models/jobitem.model";
import { tenantFlowResult } from "../Pages/integration/models/integration-models";
import { tenantSetting } from "../Models/Class-Interfaces/tenantSetting.model";
import { tenantFlowSetting, upsertTenantFlowSetting } from "../Models/Class-Interfaces/tenantFlowSetting.model";
import { tenantFlowSettingUpsertResponse } from "../Models/Class-Interfaces/tenantFlowSettingUpsert.model";
import { cancelTransferResponse } from "../Pages/transfer/models/cancelTransferResponse.model";
import { tenantFlowActionResult, toggleStateResult } from "../Models/Class-Interfaces/flow.model";
import { validateCredentialRequest } from "../Models/Class-Interfaces/validateCredentialRequest.model";
import { StateActionResult } from "../Models/Class-Interfaces/StateActionResult.model";
import { ProcessorResult } from "../Models/Class-Interfaces/ProcessorResult.model";
import { TenantFlowSecretValidationSupportedResult } from "../Models/Class-Interfaces/TenantFlowSecretValidationSupportedResult";

@Injectable({
    providedIn: 'root',
})
export class TenantAPIService extends MipHttpService {
    constructor(cognitoService: CognitoService, httpClient: HttpClient) {
        super(cognitoService, httpClient);
    }

    public getJobTypes(): Observable<jobType[]> {
        return this.get<jobType[]>('job/jobTypes');
    }

    public getJobType(flowCode: string) : Observable<jobType> {
        return this.get<jobType>(`job/jobType/${flowCode}`);
    }

    public getJobById(id: string): Observable<job> {
        return this.get<job>(`job/${id}`);
    }

    // Get Jobs for JobSearch
    public getJobs(jobId?: string, jobType?: string, jobSubType?: string, jobStatus?: JobStatus, pageSize?: number, paginationToken?: string): Observable<PaginatedResponse<job>> {
        let parameters: { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } = {};

        // Translate human-friendly parameter queryParam to parameter dictionary mipHttpService expects.
        if (jobId !== undefined) {
            parameters['jobId'] = jobId;
        }

        if (jobType !== undefined) {
            parameters['jobType'] = jobType;
        }

        if (jobSubType !== undefined) {
            parameters['jobSubType'] = jobSubType;
        }

        if (jobStatus !== undefined) {
            parameters['jobStatus'] = jobStatus;
        }

        // TODO change at a later date.
        // After discussion, will set to a very high page size number for now (ex: 1000 records)
        // TODO hookup to pagination token and such, but will never know max records that exist (i.e. infinite scrolling)
        if (pageSize !== undefined) {
            parameters['pageSize'] = pageSize;
        }
        if (paginationToken !== undefined) {
            parameters['paginationToken'] = paginationToken;
        }
        return this.get<PaginatedResponse<job>>(`job/jobs`, parameters);
    }

    // Get Job Items that need review given jobId
    public getJobItemsNeedsReview(jobId?: string): Observable<PaginatedResponse<jobItem>> {
        let parameters: { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } = {};

        // Translate human-friendly parameter queryParam to parameter dictionary mipHttpService expects.
        if (jobId !== undefined) {
            parameters['jobId'] = jobId;
        }
        return this.get<PaginatedResponse<jobItem>>(`job/jobItems/needsReview`, parameters);
    }

    // Get Error/Warning Job Items
    public getJobItems(jobId?: string, pageSize?: number): Observable<PaginatedResponse<jobItem>> {
        let parameters: { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } = {};

        // Translate human-friendly parameter queryParam to parameter dictionary mipHttpService expects.
        if (jobId !== undefined) {
            parameters['jobId'] = jobId;
        }

        if (pageSize !== undefined) {
            parameters['pageSize'] = pageSize;
        }
        return this.get<PaginatedResponse<jobItem>>(`job/jobItems/needsReview`, parameters);
    }

    public startJob(newJob: startJobRequest): Observable<job> {
        return this.post<job>('job/start', JSON.stringify(newJob));
    }

    public retryJob(retryJobRequest: retryJobRequest): Observable<retryJobResponse> {
        return this.post<retryJobRequest>('job/retry', JSON.stringify(retryJobRequest));
    }

    public getExportResultsByJobId = (jobId: string): Observable<exportResult[]> => {
        return this.get<exportResult[]>(`job/${jobId}/exportableFiles`);
    }
    public getTentant(): Observable<tenant> {
        return this.get<tenant>('user/tenant');
    }

    public InitiateDownload = (jobId: string, type: string, key: string): Observable<string> => {
        return this.getResultAsString(`job/${jobId}/download/${type}/${key}`);
    }
    public getTransferTypes = (): Observable<transferType[]> => {
        return this.get<transferType[]>('transfer/transferTypes');
    }

    public getFlowInstance = (flowType: string, flowCode: string): Observable<tenantFlowResult> => {
        return this.get<tenantFlowResult>(`flow/${flowType}/${flowCode}`);
    }

    public intitateTransfer = (transferType: string, fileName: string, contentType: string): Observable<initiateTransferResponse> => {
        var postBody: any = {
            name: transferType,
            fileName: fileName,
            contentType: contentType
        };
        return this.post('transfer/initiate', postBody);
    }

    public processTransfer = (jobId: string): Observable<processTransferResult> => {
        var postBody: any = { };
        var uri =`transfer/${jobId}/process`
        return this.post(uri, postBody);
    }

    public cancelTransfer = (jobId: string): Observable<cancelTransferResponse> => {
        var postBody: any = { };
        var uri =`transfer/${jobId}/cancel`
        return this.post(uri, postBody);
    }

    public getAllowedTenants = (): Observable<tenant[]> => {
        return this.get<tenant[]>('user/allowedTenants');
    }

    public switchTenant = (tenantId: string): Observable<void> => {
        var uri = 'user/tenant/'+tenantId;
        var postBody: any = {};
        var result = this.post<void>(uri, postBody);
        return result;
    }

    public getTenantSetting = (): Observable<tenantSetting[]> => {
        return this.get<tenantSetting[]>('tenant/settings')
    } 

    public getTenantFlowSettings(flowCode: string, type: string = "job"): Observable<tenantFlowSetting[]> {
        return this.get<tenantFlowSetting[]>(`flow/${type}/${flowCode}/settings`, { 'settingType': 'Step' });
    }

    public upsertTenantFlowStepSettings = (flowCode: string, type: string, tenantFlowSettings: upsertTenantFlowSetting[]): Observable<tenantFlowSettingUpsertResponse> => {
        return this.put<tenantFlowSettingUpsertResponse>(`flow/${type}/${flowCode}/settings`, JSON.stringify(tenantFlowSettings));
    }

    public putTenantSetting = (settingName: string, value: string, secretValues: Record<string, string>): Observable<void> => {
        var uri = `tenant/settings/${settingName}`;
        var postBody: any = 
        {
            value: value,
            secretValues: secretValues,
        };
        var result = this.put<void>(uri, postBody);
        return result;
    }

    public intitateTableSettingsTransfer = (transferType: string, fileName: string, contentType: string): Observable<initiateTransferResponse> => {
        var postBody: any = {
            name: transferType,
            fileName: fileName,
            contentType: contentType
        };
        return this.post('transfer/tablesetting/initiate', postBody);
    }

    public enable = (flowType: string, flowCode: string, headers?: { [key: string]: string }): Observable<toggleStateResult> => {
        var uri = `flow/${flowType}/${flowCode}/enable`;
        var postBody: any = {};
        var result = this.post<toggleStateResult>(uri, postBody, headers);
        return result;
    }

    public disable = (flowType: string, flowCode: string, headers?: { [key: string]: string }): Observable<toggleStateResult> => {
        var uri = `flow/${flowType}/${flowCode}/disable`;
        var postBody: any = {};
        var result = this.post<toggleStateResult>(uri, postBody, headers);
        return result;
    }

    public getFlowActionStatus = (flowType: string, flowCode: string, stateActionCode: string, stateActionId: string): Observable<tenantFlowActionResult> => {
        var uri = `flow/${flowType}/${flowCode}/action/${stateActionCode}/${stateActionId}`;
        var postBody: any = {};
        var result = this.get<tenantFlowActionResult>(uri, postBody);
        return result;
    }

    public validateCredentialsStart = (settingName: string, request: validateCredentialRequest, headers?: { [key: string]: string }): Observable<StateActionResult> => {
        var uri = `tenant/secrets/${settingName}/validate`;
        var result = this.post<StateActionResult>(uri, request, headers);
        return result;
    }

    public validateCredentialsSupported = (settingName: string): Observable<TenantFlowSecretValidationSupportedResult> => {
        var uri = `tenant/secrets/${settingName}/validate`;
        var postBody: any = {};
        var result = this.get<TenantFlowSecretValidationSupportedResult>(uri, postBody);
        return result;
    }
}
