<div *ngIf="state===TenantFlowActionResultStatus.InProgress" class="dialog-container dialog-container-busy">
  <div class="dialog-title-container dialog-title-container-busy" >
    <h1 mat-dialog-title class="dialog-title-text dialog-title-text-busy">{{ data.busy.title }}</h1>
    <button mat-icon-button mat-dialog-close class="button-close button-dialog-close" (click)="close()" [disableRipple]="true">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="dialog-content-container dialog-content-container-busy">
    <p [innerHTML]="data.busy.message" class="dialog-content-text dialog-content-text-busy"></p>
    <mat-spinner class="spinner-actions-pending" [diameter]="86" id="spinner-action-pending"></mat-spinner>
  </div>
</div>

<div *ngIf="state===TenantFlowActionResultStatus.None || state===TenantFlowActionResultStatus.Complete" class="dialog-container dialog-container-success">
  <div class="dialog-title-container dialog-title-container-success" >
    <mat-icon color="primary" class="status-icon success">done</mat-icon>
    <h1 mat-dialog-title class="dialog-title-text dialog-title-text-success">{{ data.success.title }}</h1>
    <button mat-icon-button mat-dialog-close class="button-close button-dialog-close" (click)="close()" [disableRipple]="true">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="dialog-content-container dialog-content-container-success">
    <p [innerHTML]="data.success.message" class="dialog-content-text dialog-content-text-success"></p>
  </div>
</div>

<div *ngIf="state===TenantFlowActionResultStatus.Error"class="dialog-container dialog-container-error">
  <div class="dialog-title-container dialog-title-container-error" >
    <mat-icon color="primary" class="status-icon error">block</mat-icon>
    <h1 mat-dialog-title class="dialog-title-text dialog-title-text-error">{{ data.error.title }}</h1>
    <button mat-icon-button mat-dialog-close class="button-close button-dialog-close" (click)="close()" [disableRipple]="true">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="dialog-content-container dialog-content-container-success">
    <p [innerHTML]="data.error.message" class="dialog-content-text dialog-content-text-error"></p>
  </div>
</div>
