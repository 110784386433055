<div class="container-fluid  navigation-bar" *ngIf="!loading">
    <div class="row-fluid navigation-title ">
        <h4> {{integration?.name}}</h4>
        <div style="display:flex">
            <mat-slide-toggle
                class="small-toggle tenant-enable-toggle"
                [checked]="isEnabled"
                [disabled]="flowNotFound || isEnabledChanging"
                (change)="onToggleChange($event)"
                aria-label="Toggle Integration">
                {{isEnabled ? "Connected" : "Paused"}}
            </mat-slide-toggle>
            <mat-spinner class="loading-spinner inline-spinner" *ngIf="isEnabledChanging"></mat-spinner>
            <span class="last-run-text">
                {{ lastRunDate ? 'Last run: ' + (lastRunDate | date: 'MM/dd/yyyy, hh:mm a') : 'Not run yet' }}
            </span>
        </div>

    </div>
    <div class="row-fluid navigation-header">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" [disableRipple]="true" animationDuration="300ms">
        <mat-tab label="Transfer" id="tranfer-tab-header" class="integration-tab"> <app-transfer></app-transfer> </mat-tab>
        <mat-tab label="Run" id="run-calculations-tab-header" class="integration-tab"> <app-jobs
            [integration]="integration"></app-jobs> </mat-tab>
        <mat-tab label="Table Settings" id="table-settings-tab-header" class="integration-tab" ><app-table-settings [integration]="integration"></app-table-settings></mat-tab>
        <div *ngIf="shouldShowScheduleTab" >
            <mat-tab label="Integration Schedule" id="payroll-schedule-header" class="integration-tab"><app-payroll-schedule [integration]="integration" [tenantFlowSettings]="tenantFlowSettings"></app-payroll-schedule></mat-tab>
        </div>
        <mat-tab label="Configuration" id="configuration-tab-header" class="integration-tab"><app-tenant-configuration [integration]="integration" [jobType]="jobType" [isAdmin]="isAdmin"></app-tenant-configuration></mat-tab>
        <mat-tab label="Email Notifications" id="email-notifications-tab-header"> <app-email-notification-settings></app-email-notification-settings></mat-tab>
        </mat-tab-group>
    </div>
</div>
<div *ngIf="loading && tenantFlowSettingsLoading" class="container-fluid ">
    <div class="row">
        <mat-spinner class="loading-spinner" *ngIf="loading && tenantFlowSettingsLoading" [diameter]="60"></mat-spinner>
    </div>
</div>