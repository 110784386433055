import { Component, Input, signal } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule, formatDate } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { tenantFlowResult } from '../integration/models/integration-models';
import { flowDefinitionSetting } from '../jobs/models/flowDefinitionSetting';
import { FlowSettingsService } from '../../Services/api.flowSettings.service';
import { TenantAPIService } from '../../Services/api.tenantApi.service';
import { dateFiterSetting, stringFiterSetting, tenantFlowSetting, upsertTenantFlowSetting } from '../../Models/Class-Interfaces/tenantFlowSetting.model';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { jobType } from '../jobs/models/jobtypes.model';

@Component({
  selector: 'app-tenant-configuration',
  standalone: true,
  imports: [CommonModule, MatButtonToggleModule, MatSelectModule, MatChipsModule, MatDatepickerModule, MatIconModule, MatProgressSpinnerModule, MatTabsModule, MatCardModule, MatFormFieldModule, MatInputModule, MatButtonModule, FormsModule, ReactiveFormsModule, MatCheckboxModule],
  templateUrl: './tenant-configuration.component.html',
  styleUrl: './tenant-configuration.component.scss'
})
export class TenantConfigurationComponent {
  constructor(public router: Router, private flowSettingsAPI: FlowSettingsService, private tenantAPI: TenantAPIService, private fb: FormBuilder) {
    this.tenantFlowSettingsOptionsForm = this.fb.group({
    });
  }
  @Input() integration: tenantFlowResult | null = null;
  @Input() jobType: jobType | null = null;
  @Input() isAdmin: boolean = false;
  flowSettings: flowDefinitionSetting[] = [];
  tenantFlowSettings: tenantFlowSetting[] = [];
  tenantFlowSettingsOptionsForm: FormGroup;
  submittingForm: boolean = false;
  tenantFlowSettingsLoaded: boolean = false;
  flowSettingsLoaded: boolean = false;
  allFilters: { [id: string]: string[]; } = {};
  readonly filters = signal(this.allFilters);

  ngOnInit() {
    this.loadConfigSettings();
  }

  trackByKeyword(index: number, keyword: string): string {
    return keyword;
  }

  // Triggered when we're supposed to add a chip
  addFilter(event: MatChipInputEvent, filterName: string): void {
    const value = (event.value || '').trim();
    // Add our keyword
    if (value) {
      this.filters.update(filters => {
        if (!filters[filterName]) {
          filters[filterName] = [];
        }
        if (!filters[filterName].includes(value)) {
          filters[filterName] = [...filters[filterName], value];
        }
        this.updateTextBoxState(filterName);
        return filters;
      });
    }
    // Clear the input value
    event.chipInput!.clear();
  }
  

  convertTextFieldToChip(filterName: string): void {
    const inputControl = this.tenantFlowSettingsOptionsForm.get(filterName + '-input');
    const inputText = inputControl?.value;
  
    if (inputText && typeof inputText === 'string' && inputText.trim()) {
      const trimmedText = inputText.trim();
  
      if (trimmedText) {
        // Create a mock event object for addFilter
        const mockEvent = {
          value: trimmedText,
          chipInput: { clear: () => inputControl.setValue('') }
        } as MatChipInputEvent;
  
        // Add the filter
        this.addFilter(mockEvent, filterName);
      }
    }
  }
   

  initFilter(chips: string[], filterName: string): void {
    this.filters.update(filters => {
      filters[filterName] = chips;
      return filters;
    });
    this.updateTextBoxState(filterName);
  }

  getFilters(filterName: string): string[] {
    return this.filters()[filterName] || [];
  }

  removeFilter(filter: string, filterName: string): void {
    this.filters.update(allFilters => {
      const specificFilter = allFilters[filterName] || [];
      const index = specificFilter.indexOf(filter);

      // If the filter is not found, return the current filters
      if (index < 0) {
        return allFilters;
      }

      // Remove the filter
      specificFilter.splice(index, 1);

      // Update the text box state after removing the chip
      this.updateTextBoxState(filterName);

      return allFilters;
    });
  }

  onFilterTextBoxInputChange(event: any, filterName: string): void {
    const inputText = event.target.value;
    const filters = this.getFilters(filterName);

    // Update the text box state
    this.updateTextBoxState(filterName);

    // Set custom required attributes based on the current input and filters
    const selectedOption = this.tenantFlowSettingsOptionsForm.get(filterName + '-toggle')?.value;
    this.setCustomRequiredAttributes(filterName, selectedOption);
  }

  onDatepickerInputChange(event: any, setting: any): void {
    const dateControlStart = this.tenantFlowSettingsOptionsForm.get(`${setting.stepName}-${setting.settingName}-start`);
    const dateControlEnd = this.tenantFlowSettingsOptionsForm.get(`${setting.stepName}-${setting.settingName}-end`);
    const toggleControl = this.tenantFlowSettingsOptionsForm.get(`${setting.stepName}-${setting.settingName}-toggle`);
    let startValid = dateControlStart && (!dateControlStart.errors);
    let endValid = dateControlEnd && (!dateControlEnd.errors);
    if (startValid && endValid && !dateControlStart?.value && !dateControlEnd?.value && toggleControl && toggleControl.value) {
      toggleControl.setValue('');
      this.updateDateInputState(setting);
    }
  }

  updateDateInputState(setting: any): void {
    const dateControlStart = this.tenantFlowSettingsOptionsForm.get(`${setting.stepName}-${setting.settingName}-start`);
    const dateControlEnd = this.tenantFlowSettingsOptionsForm.get(`${setting.stepName}-${setting.settingName}-end`);
    const toggleControl = this.tenantFlowSettingsOptionsForm.get(`${setting.stepName}-${setting.settingName}-toggle`);

    const toggleControlExists = toggleControl !== null;
    const selectedOption = toggleControl?.value;

    const isDisabled = (toggleControlExists && !selectedOption);

    if (isDisabled) {

      dateControlStart?.disable({ emitEvent: false });
      dateControlEnd?.disable({ emitEvent: false });
      toggleControl?.setValue('');
    } else {
      dateControlStart?.enable({ emitEvent: false });
      dateControlEnd?.enable({ emitEvent: false });
    }
  }

  updateTextBoxState(filterName: string): void {
    const selectedOption = this.tenantFlowSettingsOptionsForm.get(filterName + '-toggle')?.value;

    // Check if the toggle control exists (i.e. the filter has a filter type dropdown)
    const toggleControlExists = this.tenantFlowSettingsOptionsForm.get(filterName + '-toggle') !== null;

    // Disable the chip input if no filter option is selected and the field is a text type
    const isDisabled = (toggleControlExists && !selectedOption);
    const textBoxControl = this.tenantFlowSettingsOptionsForm.get(filterName);

    if (isDisabled) {
      textBoxControl?.disable({ emitEvent: false });
    } else {
      textBoxControl?.enable({ emitEvent: false });
    }
  }

  setCustomRequiredAttributes(filterName: string, selectedOption: string): void {
    const setting = this.flowSettings.find(o => o.stepName + '-' + o.settingName === filterName);
    const textBoxControl = this.tenantFlowSettingsOptionsForm.get(filterName);
    const inputControl = this.tenantFlowSettingsOptionsForm.get(filterName + '-input');
    const filters = this.getFilters(filterName);

    if (!setting) {
      return; // Exit if setting is not found
    }

    if (setting.type === 'filter-string') {
      if (selectedOption && filters.length === 0 && !inputControl?.value) {
        textBoxControl?.setErrors({ required: true });
      } else {
        textBoxControl?.setErrors(null);
      }
    } else if (setting.type === 'filter-string-with-null') {
      if (selectedOption === 'includeFilled' || selectedOption === 'excludeEmpty') {
        if (filters.length === 0 && !inputControl?.value) {
          textBoxControl?.setErrors({ required: true });
        } else {
          textBoxControl?.setErrors(null);
        }
      } else {
        textBoxControl?.setErrors(null);
      }
    }

    // Force validation update
    textBoxControl?.updateValueAndValidity({ emitEvent: false });
  }


  isFilterTextBoxDisabled(setting: any): boolean {
    const selectedOption = this.tenantFlowSettingsOptionsForm.get(setting.stepName + '-' + setting.settingName + '-toggle')?.value;
    const filters = this.getFilters(setting.stepName + '-' + setting.settingName);
    const inputText = this.tenantFlowSettingsOptionsForm.get(setting.stepName + '-' + setting.settingName)?.value || '';

    // Disable if no option is selected and no filters exist and no text is present
    return !selectedOption && filters.length === 0 && !inputText;
  }

  toggleChange(event: any, parent: any) {
    const selectedValue = event.value;

    const parentControl = this.tenantFlowSettingsOptionsForm.get(parent);
    const startControl = this.tenantFlowSettingsOptionsForm.get(`${parent}-start`);
    const endControl = this.tenantFlowSettingsOptionsForm.get(`${parent}-end`);
    const toggleControl = this.tenantFlowSettingsOptionsForm.get(`${parent}-toggle`);

    if (!selectedValue) {
      parentControl?.disable({ emitEvent: false });
      startControl?.disable({ emitEvent: false });
      endControl?.disable({ emitEvent: false });
    } else {
      parentControl?.enable({ emitEvent: false });
      startControl?.enable({ emitEvent: false });
      endControl?.enable({ emitEvent: false });
    }

    // Update the text box state based on the current filters and selected option
    this.updateTextBoxState(parent);

    // Set custom required attributes based on the dropdown state
    this.setCustomRequiredAttributes(parent, selectedValue);

    // Mark the form control as touched and trigger change detection
    toggleControl?.markAsTouched();
    this.tenantFlowSettingsOptionsForm.updateValueAndValidity();

    // Manually trigger change detection
    //this.cdr.detectChanges();
  }



  isRequired(setting: any): boolean {
    const selectedOption = this.tenantFlowSettingsOptionsForm.get(setting.stepName + '-' + setting.settingName + '-toggle')?.value;
    const filters = this.getFilters(setting.stepName + '-' + setting.settingName);
    const inputControl = this.tenantFlowSettingsOptionsForm.get(setting.stepName + '-' + setting.settingName + '-input');

    if (setting?.type === 'filter-string') {
      return selectedOption // && !inputControl?.value;  filters.length === 0
    } else if (setting?.type === 'filter-string-with-null') {
      return (selectedOption === 'includeFilled' || selectedOption === 'excludeEmpty') // && !inputControl?.value; // && filters.length === 0 
    }
    return false;
  }

  updateForm(flowSettings: flowDefinitionSetting[], tenantFlowSettings: tenantFlowSetting[]) {
    if (!this.tenantFlowSettingsLoaded || !this.flowSettingsLoaded) {
      return;
    }

    // Add new form controls on switch type
    flowSettings.forEach(setting => {
      let formControlDefault: any | null = null;
      let formControlName = `${setting.stepName}-${setting.settingName}`;
      let disableParent = false;

      if (!setting.isReadOnly) {
        // Remove the control to avoid vestigial values
        this.tenantFlowSettingsOptionsForm.removeControl(formControlName);

        // Check if tenant has settings already, if not, use default values
        const tenantSetting = tenantFlowSettings.find(o => o.settingName === setting.settingName && o.stepName === setting.stepName);

        if (setting.type === "filter-string" || setting.type === "filter-string-with-null") {
          this.tenantFlowSettingsOptionsForm.removeControl(`${formControlName}-toggle`);
          const filter: stringFiterSetting = tenantSetting?.value ? JSON.parse(tenantSetting.value) : {} as stringFiterSetting;

          // Fix sync issues before loading
          if (filter.value === null) {
            filter.comparison = filter.comparison ?? '';
          }

          const list = filter.value?.split(",") ?? [];
          this.initFilter(list, formControlName);

          const comparison = filter.comparison ?? '';
          const toggleControl = this.fb.control(
            { value: comparison, disabled: false },
            setting.required ? Validators.required : null
          );

          const inputControl = this.fb.control('');
          this.tenantFlowSettingsOptionsForm.addControl(`${formControlName}-toggle`, toggleControl);
          this.tenantFlowSettingsOptionsForm.addControl(`${formControlName}-input`, inputControl);

          if (!filter.comparison) {
            disableParent = true;
          }

          this.updateTextBoxState(formControlName);
          this.setCustomRequiredAttributes(formControlName, comparison);
        } else if (setting.type === "filter-date" && tenantSetting !== null) {
          const filter: dateFiterSetting = tenantSetting?.value ? JSON.parse(tenantSetting.value) : {} as dateFiterSetting;
          const dates = filter.value?.split(",") ?? [];

          if (dates.length === 2) {
            filter.startDate = dates[0] === '' ? null : new Date(dates[0]);
            filter.endDate = dates[1] === '' ? null : new Date(dates[1]);
          }

          const startControl = this.fb.control(
            { value: filter.startDate, disabled: false },
            setting.required ? Validators.required : null
          );
          const endControl = this.fb.control(
            { value: filter.endDate, disabled: false },
            setting.required ? Validators.required : null
          );

          this.tenantFlowSettingsOptionsForm.addControl(`${formControlName}-start`, startControl);
          this.tenantFlowSettingsOptionsForm.addControl(`${formControlName}-end`, endControl);

          const comparison = filter.comparison ?? '';
          const toggleControl = this.fb.control(
            { value: comparison, disabled: false },
            setting.required ? Validators.required : null
          );

          this.tenantFlowSettingsOptionsForm.addControl(`${formControlName}-toggle`, toggleControl);

          if (!filter.comparison) {
            disableParent = true;
          }

          this.updateTextBoxState(formControlName);
          this.setCustomRequiredAttributes(formControlName, comparison);
        } else if (tenantSetting) {
          formControlDefault = setting.type === 'bool' ? tenantSetting.value === 'true' : tenantSetting.value;
        } else {
          formControlDefault = setting.defaultValue;
        }

        const control = this.fb.control(
          { value: formControlDefault, disabled: disableParent },
          setting.required ? Validators.required : null
        );

        this.tenantFlowSettingsOptionsForm.addControl(formControlName, control);
      }
    });
  }

  hideForm = (): boolean => {
    return this.integration?.flowCode == null || !this.tenantFlowSettingsLoaded || !this.flowSettingsLoaded; // Proxy for unloaded job types
  }

  updateFormAfterLoad() {
    if (this.tenantFlowSettingsLoaded && this.flowSettingsLoaded) {
      this.updateForm(this.flowSettings, this.tenantFlowSettings);
    }
  }

  loadConfigSettings() {
    if (!this.integration) {
      return;
    }

    this.tenantAPI.getTenantFlowSettings(this.integration.flowCode).subscribe({
      next: (data: tenantFlowSetting[]) => {
        this.tenantFlowSettings = data;
      },
      error: (result: any) => { console.log(result); },
      complete: () => {
        this.tenantFlowSettingsLoaded = true;
        this.updateFormAfterLoad(); // Update form after loading tenant settings
      }
    });

    this.flowSettingsAPI.getStepFlowDefinitionSettings(this.integration.flowCode, this.integration.version).subscribe({
      next: (data: flowDefinitionSetting[]) => {
        this.flowSettings = data;
      },
      error: (result: any) => { console.log(result) },
      complete: () => {
        this.flowSettings = this.flowSettings.sort((a, b) => {
          let orderA = a.sortPriority !== null ? a.sortPriority : Number.MAX_SAFE_INTEGER;
          let orderB = b.sortPriority !== null ? b.sortPriority : Number.MAX_SAFE_INTEGER;
          return orderA - orderB;
        });
        this.flowSettingsLoaded = true;
        this.updateFormAfterLoad(); // Update form after loading flow settings
      }
    });
  }

  saveConfig = (): void => {
    // Convert any text in the input boxes to chips before saving
    for (let field in this.tenantFlowSettingsOptionsForm.controls) {
      let setting = this.flowSettings.find(o => o.stepName + '-' + o.settingName === field);
      if (setting && (setting.type === 'filter-string' || setting.type === 'filter-string-with-null')) {
        this.convertTextFieldToChip(field);
      }
    }
    
    if (this.tenantFlowSettingsOptionsForm.invalid) {
      return;
    }
  
    this.submittingForm = true;
    let formFields = [];
    let settingVal: string;
  
    // Convert form fields to settings to be upserted
    for (let field in this.tenantFlowSettingsOptionsForm.controls) {
      let setting = this.flowSettings.find(o => o.stepName + '-' + o.settingName === field);
      if (setting !== undefined) {
        if ((setting.type === "filter-string" || setting.type === "filter-string-with-null") && this.tenantFlowSettingsOptionsForm.controls[field + "-toggle"]?.value !== null) {
          let filter: stringFiterSetting = {} as stringFiterSetting;
          filter.comparison = this.tenantFlowSettingsOptionsForm.controls[field + "-toggle"]?.value?.toString();
          let chips = this.getFilters(field);
          filter.value = chips.length > 0 ? chips.join(",") : null;
          settingVal = JSON.stringify(filter);
        } else if (setting.type === "filter-date" && this.tenantFlowSettingsOptionsForm.controls[field + "-toggle"]?.value !== null) {
          let filter: dateFiterSetting = {} as dateFiterSetting;
          let startDate: Date | null = this.tenantFlowSettingsOptionsForm.controls[field + "-start"]?.value;
          let endDate: Date | null = this.tenantFlowSettingsOptionsForm.controls[field + "-end"]?.value;
  
          const dateFormat = "yyyy-MM-ddTHH:mm:ss";
          const dateLocale = "en-US";
          let formattedStartDate = startDate ? formatDate(startDate.toISOString(), dateFormat, dateLocale) : "";
          let formattedEndDate = endDate ? formatDate(endDate.toISOString(), dateFormat, dateLocale) : "";
  
          filter.comparison = this.tenantFlowSettingsOptionsForm.controls[field + "-toggle"]?.value?.toString();
          filter.value = `${formattedStartDate},${formattedEndDate}`;
          settingVal = JSON.stringify(filter);
        } else if (this.tenantFlowSettingsOptionsForm.controls[field].touched) {
          settingVal = this.tenantFlowSettingsOptionsForm.controls[field].value.toString();
        } else {
          continue;
        }
  
        let formField = new upsertTenantFlowSetting(
          setting.settingName,
          setting.displayName,
          setting.settingType,
          setting.stepName,
          setting.type,
          settingVal,
          setting.isReadOnly,
          setting.subType,
          setting.stepScheduleName,
          setting.stepScheduleDetailType,
          setting.enableSchedule
        );
        formFields.push(formField);
      }
    }
  
    if (this.integration !== null) {
      this.tenantAPI.upsertTenantFlowStepSettings(this.integration?.flowCode, this.integration?.type, formFields).subscribe({
        error: (result: any) => { console.log(result); },
        complete: () => {
          this.submittingForm = false;
          this.tenantFlowSettingsLoaded = false;
          this.flowSettingsLoaded = false;
          this.loadConfigSettings();
        }
      });
    }
  }  

  cannotSubmit = (): boolean => {
    return !this.integration || !this.tenantFlowSettingsOptionsForm.valid || this.submittingForm;
  }

  setRequired = (formControlName: string) => {
    this.tenantFlowSettingsOptionsForm.controls[formControlName]?.setValidators(Validators.required);
    this.tenantFlowSettingsOptionsForm.controls[formControlName]?.updateValueAndValidity();;
  }

  clearRequired = (formControlName: string) => {
    this.tenantFlowSettingsOptionsForm.controls[formControlName]?.clearValidators();
    this.tenantFlowSettingsOptionsForm.controls[formControlName]?.updateValueAndValidity();;
  }

  isSelfService = () => {
    return this.jobType && (this.jobType.isSelfService || this.isAdmin);
  }
}